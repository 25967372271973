<template>
  <router-view/>
</template>
<script>
export default {
  name: 'App',
  updated() {
    console.log(this.$breadcrumbs.value);
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input {
  border: 1px solid black;
  border-radius: 0;
  outline: none;
}

html {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #000;
}
h1{
  font-weight: 700;
}
 h2, h3, h4, h5 {
  font-size: unset;
  font-weight: normal;
}

.wrapper {
  max-width: rem(1250);
  margin: 0 auto;
  padding: 0 rem(16);
  position: relative;
}
.different-color {
  color: #93383D;
}
main {
  background-image: url("http://tpk-tt.nichost.ru/bg.png");
  background-repeat: no-repeat;
  background-position: right rem(40);
}
</style>
