import {createRouter, createWebHistory} from 'vue-router'

const Home = () => import('@/pages/Home.vue');
const AboutUs = () => import ("@/pages/AboutUs");
const OurProducts = () => import( "@/pages/OurProducts/OurProducts");
const OurProjects = () => import ("@/pages/OurProjects/OurProjects");
const ProductElectrictrain = () => import('@/pages/OurProducts/ProductElectrictrain');
const ProductRefrigerator = () => import('@/pages/OurProducts/ProductRefrigerator');
const ProductTram = () => import ("@/pages/OurProducts/ProductTram");
const EP2D = () => import('@/pages/OurProducts/EP2D');
const EP3D = () => import('@/pages/OurProducts/EP3D');
const TramPKTS = () => import('@/pages/OurProducts/TramPKTS');
const TramUKVZ = () => import('@/pages/OurProducts/TramUKVZ');
const Isotermic = () => import('@/pages/OurProjects/Isotermic');
const Condition = () => import('@/pages/OurProjects/Condition');
const Electrostation = () => import('@/pages/OurProjects/Electrostation');
const Dispatcher = () => import('@/pages/OurProjects/Dispatcher');
const NewsItem = () => import('@/pages/NewsItem');
const Contact = () => import('@/pages/Contact');
const News = () => import('@/pages/News.vue');
const inDev = () => import('@/pages/inDev.vue');

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/aboutUs',
        name: 'AboutUs',
        meta: {
            pageName: 'О компании'
        },
        component: AboutUs,
    },
    {
        path: '/ourproducts',
        name: 'OurProducts',
        meta: {
            pageName: 'Наша продукция'
        },
        children: [
            {
                path: 'productElectrictrain',
                name: 'ProductElectrictrain',
                meta: {
                    pageName: 'Электропоезда'
                },
                component: ProductElectrictrain,
                children: [
                    {
                        path: 'ep2d',
                        name: 'EP2D',
                        meta: {
                            pageName: 'Электропоезд ЭП-2Д'
                        },
                        component: EP2D,

                    },
                    {
                        path: 'ep3d',
                        name: 'EP3D',
                        meta: {
                            pageName: 'Электропоезд ЭП-3Д'
                        },
                        component: EP3D,

                    },
                ]
            },
            {
                path: 'refrigerator',
                name: 'Refrigerator',
                meta: {
                    pageName: 'Рефрижераторы'
                },
                component: ProductRefrigerator,
            },
            {
                path: 'tram',
                name: 'Tram',
                meta: {
                    pageName: 'Трамваи'
                },
                component: ProductTram,
                children: [
                    {
                        path: 'tramPKTS',
                        name: 'TramPKTS',
                        meta: {
                            pageName: 'Трамвайный вагон ПТКС'
                        },
                        component: TramPKTS,
                    },
                    {
                        path: 'tramUKVZ',
                        name: 'TramUKVZ',
                        meta: {
                            pageName: 'Трамвайый вагон УКВЗ'
                        },
                        component: TramUKVZ,
                    }
                ]
            }
        ],
        component: OurProducts,
    },
    {
        path: '/ourprojects',
        name: 'OurProjects',
        meta: {
            pageName: 'Нaши проекты'
        },
        component: OurProjects,
        children: [
            {
                path: 'condition',
                name: 'Condition',
                meta: {
                    pageName: 'Система Кондиционирования Воздуха'
                },
                component: Condition,
            },
            {
                path: 'isotermic',
                name: 'Isotermic',
                meta: {
                    pageName: 'Изотермический транспортировочный комплекс'
                },
                component: Isotermic,

            },
            {
                path: 'electrostation',
                name: 'Electrostation',
                meta: {
                    pageName: 'Электростпнция контейнерная многоагрегатная'
                },
                component: Electrostation,

            },
            {
                path: 'dispatcher',
                name: 'Dispatcher',
                meta: {
                    pageName: 'Диспетчерская Рефконтейнерного Ж/Д модуля'
                },
                component: Dispatcher,

            },
        ]
    },

    {
        path: '/news',
        name: 'News',
        meta: {
            pageName: 'Новости'
        },
        component: News,
        children: [
            {
                path: 'newsitem',
                name: 'NewsItem',
                meta: {
                    pageName: 'Инновации в сфере кондиционирования'
                },
                component: NewsItem
            }
        ]
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            pageName: 'Контакты'
        },
        component: Contact,
    },
    {
        path: '/indev',
        name: 'inDev',
        component: inDev,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
