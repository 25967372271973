import {createStore} from 'vuex'
import router from "@/router";

export default createStore({
    state: () => ({
            display_width: 0,
            showMenu: false,
            productMainCategories: [
                {
                    img: 'https://tpc-tt.site/img_server/our_products/train.png',
                    id: '1',
                    is_sub: true,
                    path: '/ourproducts/productElectrictrain',
                    name: 'Электро Поезда',
                    description: 'оборудование для электропоездов'
                },
                {
                    img: 'https://tpc-tt.site/img_server/our_products/train.png',
                    id: '2',
                    path: '/indev',
                    is_sub: false,
                    name: 'Модернизации',
                    description: 'оборудование для рефрижераторов'
                },
                {
                    img: 'https://tpc-tt.site/img_server/our_products/refregiretoin.png',
                    id: '3',
                    is_sub: false,
                    path: '/ourproducts/refrigerator',
                    name: 'Рефрижераторы',
                    description: 'оборудование для рефрижераторов'
                },
                {
                    img: 'https://tpc-tt.site/img_server/our_products/train.png',
                    id: '4',
                    is_sub: false,
                    path: '/indev',
                    name: 'ТО и сервисный ремонт',
                    description: ''
                },
                {
                    img: 'https://tpc-tt.site/img_server/our_products/tram.png',
                    id: '5',
                    is_sub: true,
                    path: '/ourproducts/tram',
                    name: 'Трамваи',
                    description: 'оборудование для трамваев'
                },
                {
                    img: 'https://tpc-tt.site/img_server/our_products/train.png',
                    id: '6',
                    is_sub: false,
                    path: '/indev',
                    name: 'ТКБ-7',
                    description: ''
                },
                {
                    img: 'https://tpc-tt.site/img_server/our_products/accessories.png',
                    id: '7',
                    is_sub: false,
                    path: '/indev',
                    name: 'Электронные и электрические компоненты и комплектующие',
                    description: ''
                },
                {
                    img: 'https://tpc-tt.site/img_server/our_products/indev.png',
                    id: '8',
                    is_sub: false,
                    path: '/indev',
                    name: 'В разработке',
                    description: ''
                }

            ],
            productSubCategories: [
                {
                    id: '1',
                    parent_id: '1',
                    name: 'Электропоезд ЭП-2Д',
                    path: '/ourproducts/productElectrictrain/ep2d'
                },
                {
                    id: '2',
                    parent_id: '1',
                    name: 'Электропоезд ЭП-3Д',
                    path: '/ourproducts/productElectrictrain/ep3d'
                },
                {
                    id: '3',
                    parent_id: '5',
                    name: 'Трамвай ПКТС',
                    path: '/ourproducts/tram/tramPKTS'
                },
                {
                    id: '4',
                    parent_id: '5',
                    name: 'Трамвай УКВЗ',
                    path: '/ourproducts/tram/tramUKVZ'
                }
            ],
            projectMainCategories: [
                {
                    id: '1',
                    path: '/ourprojects/isotermic',
                    name: 'Изотермический транспортировочный комплекс'
                },
                {
                    id: '2',
                    path: '/ourprojects/condition',
                    name: 'Система кондиционирования воздуха для подвижного состава РЖД'
                },
                {
                    id: '3',
                    path: '/indev',
                    name: 'Проект вентиляции и кондиционирования  для вагонов метро'
                },
                {
                    id: '4',
                    path: '/ourprojects/electrostation',
                    name: 'Электростанция контейнерная многоагрегатная'
                },
                {
                    id: '5',
                    path: '/ourprojects/dispatcher',
                    name: 'Диспетчеризация рефконтейнерного ж/д модуля'

                },
                {
                    id: '6',
                    path: '/indev',
                    name: 'Изотермический транспортировочный комплекс'
                },
                {
                    id: '7',
                    path: '/indev',
                    name: 'Система микроклимата салона трамвая и кабины машиниста для ООО “ Синара-Шкода”'
                },
                {
                    id: '8',
                    path: '/indev',
                    name: 'В разработке'
                }
            ],
            news: [
                {
                    path: '/news/newsitem',
                    img: 'https://tpc-tt.site/img_server/news.png',
                    id: '1',
                    name: 'Инновации в сфере кондиционирования',
                    description: 'В марте 2018 года на электропоездах ЭД4М по заказу «ЗАО Спецремонт» были установлены системы кондиционирования и отопления воздуха.'
                },
                {
                    path: '/news/newsitem',
                    img: 'https://tpc-tt.site/img_server/news.png',
                    id: '2',
                    name: 'Инновации в сфере кондиционирования',
                    description: 'В марте 2018 года на электропоездах ЭД4М по заказу «ЗАО Спецремонт» были установлены системы кондиционирования и отопления воздуха.'
                },
                {
                    path: '/news/newsitem',
                    img: 'https://tpc-tt.site/img_server/news.png',
                    id: '3',
                    name: 'Инновации в сфере кондиционирования',
                    description: 'В марте 2018 года на электропоездах ЭД4М по заказу «ЗАО Спецремонт» были установлены системы кондиционирования и отопления воздуха.'
                },
                {
                    path: '/news/newsitem',
                    img: 'https://tpc-tt.site/img_server/news.png',
                    id: '4',
                    name: 'Инновации в сфере кондиционирования',
                    description: 'В марте 2018 года на электропоездах ЭД4М по заказу «ЗАО Спецремонт» были установлены системы кондиционирования и отопления воздуха.'
                },
                {
                    path: '/news/newsitem',
                    img: 'https://tpc-tt.site/img_server/news.png',
                    id: '5',
                    name: 'Инновации в сфере кондиционирования',
                    description: 'В марте 2018 года на электропоездах ЭД4М по заказу «ЗАО Спецремонт» были установлены системы кондиционирования и отопления воздуха.'
                },
                {
                    path: '/news/newsitem',
                    img: 'https://tpc-tt.site/img_server/news.png',
                    id: '6',
                    name: 'Инновации в сфере кондиционирования',
                    description: 'В марте 2018 года на электропоездах ЭД4М по заказу «ЗАО Спецремонт» были установлены системы кондиционирования и отопления воздуха.'
                }
            ],
        }
    ),


    getters: {},
    mutations: {
        SET_DISPLAY_WIDTH(state, width) {
            state.display_width = width;
        },
        CHANGE_MENU(state) {
            state.showMenu = !state.showMenu
        },
    },
    actions: {},
    modules: {}
})
